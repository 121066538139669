/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

 function Header(){
  return (
    <header className="d-flex sticky-top bg-white flex-wrap justify-content-center py-3 mb-4 border-bottom">
      <a
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
      >
        <span className="fs-4"><img src="logo-pc.svg" className="App-logo" alt="logo" /></span>
      </a>

      <ul className="nav nav-pills">
        <li className="nav-item">
          <a href="/" className="nav-link" aria-current="page">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="/#about" className="nav-link">
            About
          </a>
        </li>
        <li className="nav-item">
          <a href="/#services" className="nav-link">
            Products &amp; Services
          </a>
        </li>
        <li className="nav-item">
          <a href="/#contact" className="nav-link">
            Contact Us
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
